.logo{
    margin-top: 16px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.responsiveImage {
    width: 100%;
    max-width: 468px;
    margin-bottom: 32px;
    height: auto;
}

.titleText {
    max-width: 468px;
    text-align: center;
    font-family: 'Sora', sans-serif !important;
    margin-bottom: 16px;
    font-weight: 800;
    font-size: 24px !important;
    line-height: 30px !important;
    letter-spacing: 0.36px;
}

.centeredDiv{
    display: flex;
    padding: 0 16px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
}

.starsRating {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
}

.stars {
    margin-right: 8px;
}

.ratingText{
    font-family: 'Inter', sans-serif !important;
    font-weight: 400;
    font-size: 16px !important;
    line-height: 24px !important;
}

.actionButton {
    padding: 12px !important;
    min-height: 52px !important;
    height: 52px !important;
    max-width: 468px;
}

.actionButton p {
    font-family: 'Inter', sans-serif !important;
    font-size: 16px !important;
    line-height: 24px !important;
    font-weight: 700;
    text-transform: none;
    color: #ffffff;
}

.secureTitleSpan1 {
    color: #4C61C7;
}

@media screen and (min-width: 420px) and (min-height: 800px)  {
    .titleText {
        font-size: 32px !important;
        line-height: 40px !important;
        font-weight: 700;
    }

    .ratingText{
        font-size: 20px !important;
        line-height: 28px !important;
    }

    .actionButton p {
        font-size: 20px !important;
        line-height: 28px !important;
    }
}

@media screen and (max-height: 700px){
    .titleText {
        font-size: 20px !important;
        line-height: 26px !important;
        font-weight: 700;
    }

    .ratingText{
        font-size: 14px !important;
        line-height: 22px !important;
    }

    .responsiveImage{
        max-height: 260px;
    }
}
