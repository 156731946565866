.logo{
    margin-top: 16px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.responsiveVideo {
    width: 100%;
    max-width: 468px;
    margin-bottom: 32px;
    clip-path: inset(1px 1px);
    height: auto;
}

.responsiveVideo:focus { outline:none; }

.examinexContainer {
    display: flex;
    padding: 0 16px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    overflow: hidden;
}

.examinexContainer .titleText {
    max-width: 468px;
    text-align: center;
    font-family: 'Sora', sans-serif;
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.36px;
}

.examinexContainer .captionText {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    line-height: 16px;
    color: #A19EBE;
    margin-bottom: 16px;
}

.examinexContainer .actionButton {
    padding: 12px;
    min-height: 52px;
    height: 52px;
    max-width: 468px;
}

.examinexContainer .actionButton p {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    text-transform: none;
    color: #ffffff;
}

span.examinexTitleSpan1 {
    color: #4C61C7;
}

span.examinexTitleSpan2 {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0.36px;
    color: #4C61C7;
}

@media screen and (min-width: 420px) and (min-height: 800px)  {
    .examinexContainer .titleText {
        font-size: 32px;
        line-height: 40px;
        font-weight: 800;
    }

    span.examinexTitleSpan2 {
        font-size: 48px;
        line-height: 60px;
        font-weight: 800;
        letter-spacing: 0.25px;
    }

    .examinexContainer .captionText {
        font-size: 14px;
        line-height: 21px;
    }

    .examinexContainer .actionButton p {
        font-size: 20px;
        line-height: 28px;
    }
}

@media screen and (max-height: 700px){
    .examinexContainer .titleText {
        font-size: 20px;
        line-height: 26px;
        font-weight: 700;
    }

    .responsiveVideo{
        max-height: 260px;
    }
}
